import styles from './FileView.module.css'

function FileView(props) {
    const { src, videoEnded } = props

    return (
        <div className={styles.wrapper}>
            <iframe className={`${styles.iframe} ${videoEnded ? styles.show : ''}`} title='File' src={`${src}#toolbar=0&navpanes=0`} ></iframe>
        </div>
    )
}

export default FileView