import { useEffect, useState } from 'react';
import styles from './VideoPrize.module.css';

import volumeSVG from '../assets/svgs/volume.svg';
import volumeSlashSVG from '../assets/svgs/volume-slash.svg';

function VideoPrize(props) {
	const { src, onEnded, videoEnded } = props
	const [videoReady, setVideoReady] = useState(false)
	const [isMuted, setIsMuted] = useState(true)

	const volumeSrc = isMuted
		?
		volumeSlashSVG
		:
		volumeSVG

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth

			if (width < 768 * window.devicePixelRatio) {
				setIsMuted(true)
			} else {
				setIsMuted(false)
			}
		}
		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const handleToggleMuted = () => {
		setIsMuted(!isMuted)
	}

	return (
		<>
			{
				!videoEnded &&
				<div className={styles.toggleMuted} onClick={handleToggleMuted}>
					<img src={volumeSrc} alt='Toggle Muted' />
				</div>
			}

			<video
				className={`${styles.video} ${videoReady ? styles.show : ''} ${videoEnded ? styles.hide : ''}`}
				src={src}
				muted={isMuted}
				autoPlay={true}
				controls={false}
				loop={false}
				playsInline={true}
				onCanPlay={() => setVideoReady(true)}
				onEnded={onEnded}
			></video>
		</>

	)
}

export default VideoPrize