import { useState } from 'react';
import styles from './App.module.css';

import Form from './components/Form';
import VideoPrize from './components/VideoPrize';
import FileView from './components/FileView';
import Download from './components/Download';

import logo from './assets/imgs/logo.png';
import text from './assets/imgs/text.png';
import Loading from './components/Loading';
import Background from './components/Background';

function App() {
	const [videoPrizeSrc, setVideoPrizeSrc] = useState('')
	const [fileSrc, setFileSrc] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [videoEnded, setVideoEnded] = useState(false)

	const handleFormSubmit = async (payload) => {
		setIsLoading(true)

		const apiUrl = process.env.REACT_APP_API_URL
		const searchParams = {
			action: 'search'
		}
		const response = await fetch(`${apiUrl}/?${new URLSearchParams(searchParams)}`, {
			method: 'POST',
			body: JSON.stringify(payload)
		})

		setIsLoading(false)

		if (!response?.ok) {
			const error = await response.json()
			console.error(error)
			alert(error.message)
			return;
		}

		const responseJSON = await response.json()

		const downloadParams = {
			action: 'download',
			jwt: responseJSON.data.jwt
		}
		setFileSrc(`${process.env.REACT_APP_API_URL}/?${new URLSearchParams(downloadParams)}`)

		const videoParams = {
			action: 'video',
			jwt: responseJSON.data.jwt
		}
		setVideoPrizeSrc(`${process.env.REACT_APP_API_URL}/?${new URLSearchParams(videoParams)}`)
	}

	const handleVideoEnded = () => {
		setVideoEnded(true)
	}

	return (
		<>
			<Background />
			{
				!videoPrizeSrc &&
				<div className={styles.wrapper}>
					<div className={styles.colLeft}>
						<img src={logo} className={styles.logo} alt='Logo' />
						<img src={text} className={styles.text} alt='IGS Closing Ceremony' />
						<Form handleFormSubmit={handleFormSubmit} />
					</div>
					<div className={styles.colRight}>
						
					</div>
				</div>
			}
			{
				videoPrizeSrc &&
				<VideoPrize src={videoPrizeSrc} onEnded={handleVideoEnded} videoEnded={videoEnded} />
			}
			{
				fileSrc &&
				<FileView src={fileSrc} videoEnded={videoEnded} />
			}
			{
				fileSrc &&
				<Download fileSrc={fileSrc} videoSrc={videoPrizeSrc} />
			}
			{
				isLoading &&
				<Loading />
			}
		</>
	);
}

export default App;
