import videoSVG from '../assets/svgs/film.svg';
import fileSVG from '../assets/svgs/file-certificate.svg';
import downloadSVG from '../assets/svgs/arrow-down-to-line.svg';
import styles from './Download.module.css'
import { useState } from 'react';

function Download(props) {
	const { fileSrc, videoSrc } = props
	const [isFileBtnHovered, setIsFileBtnHovered] = useState(false)
	const [isVideoBtnHovered, setIsVideoBtnHovered] = useState(false)

	const handleVideoMouseEnter = (e) => {
		setIsVideoBtnHovered(true)
	}

	const handleVideoMouseLeave = (e) => {
		setIsVideoBtnHovered(false)
	}

	const handleFileMouseEnter = (e) => {
		setIsFileBtnHovered(true)
	}

	const handleFileMouseLeave = (e) => {
		setIsFileBtnHovered(false)
	}

	return (
		<div className={styles.download}>
			{
				videoSrc &&
				<a
					className={`${styles.downloadBtn} ${styles.videoBtn}`}
					href={videoSrc}
					target='_blank'
					rel='noreferrer'
					onMouseEnter={handleVideoMouseEnter}
					onMouseLeave={handleVideoMouseLeave}
				>
					<img src={isVideoBtnHovered ? downloadSVG : videoSVG} alt='Video Download Button' />
				</a>
			}

			{
				fileSrc &&
				<a
					className={`${styles.downloadBtn} ${styles.fileBtn}`}
					href={fileSrc}
					target='_blank'
					rel='noreferrer'
					onMouseEnter={handleFileMouseEnter}
					onMouseLeave={handleFileMouseLeave}
				>
					<img src={isFileBtnHovered ? downloadSVG : fileSVG} alt='File Download Button' />
				</a>
			}
		</div>
	)
}

export default Download