import { useState } from "react"

import styles from './Form.module.css'

import submitImg from '../assets/imgs/submit-btn.png'

function Form(props) {
    const [info, setInfo] = useState({
        name: '',
        birthday: '',
        city: '',
        class: ''

    })
    const { handleFormSubmit } = props

    const handleInputChange = (e) => {
        setInfo(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleFormSubmit(info)
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
                <input className={styles.input} type="text" name="name" value={info.name} placeholder="Full Name: " required={true} onChange={handleInputChange} />
            </div>

            <div className={styles.inputGroup}>
                <input className={styles.input} type="tel" name="class" value={info.class} placeholder="Class: " required={true} onChange={handleInputChange} />
            </div>

            <button type="submit" className={styles.submit}>
                <img src={submitImg} alt="Submit" />
            </button>
        </form>
    )
}

export default Form